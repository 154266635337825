import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BASE_PATH, TITLE } from ".";
import {
  Alert,
  Steps,
  Upload,
  UploadProps,
  message,
  Button,
  Divider,
  Tooltip,
  Collapse,
  Table,
  Space,
  Tag,
} from "antd";
import * as XLSX from "xlsx";
import { InboxOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { titleCase } from "title-case";
import { useDispatch, useSelector } from "react-redux";
import * as poActions from "../../store/poSlice";
import { Store } from "../../store";
import service from "../../services/po";
import vendorService from "../../services/vendor";
import settingsService from "../../services/settings";

const { Dragger } = Upload;

const REQUIRED_COLUMNS = [
  "Product Sku Code",
  "Vendor Code",
  "Warehouse",
  "Order Date",
  "Expiry Date",
  "Start Date",
  "Qty",
];

const getNonZeroRows = (rows: any[]) => {
  return rows
    .map((r) => ({
      ...r,
      total: Object.keys(r)
        .map((k) => +k)
        .filter((k) => !isNaN(k))
        .reduce((acc, k) => acc + r[k], 0),
    }))
    .filter((r) => r.total > 0);
};

const getSignificantRows = (rows: any[]) => {
  return rows
    .map((r) => ({
      ...r,
      total: Object.keys(r)
        .map((k) => +k)
        .filter((k) => !isNaN(k))
        .reduce((acc, k) => acc + r[k], 0),
    }))
    .filter((r) => r.total > 3);
};

const getUnassignedWarehouseRows = (rows: any[]) => {
  return rows.filter((r) => r["Warehouse"] === "Unassigned");
};

const validateWarehouses = (warehouses: string[]) => {
  return settingsService.validateWarehouses(warehouses);
};

const UploadRender: React.FC<any> = ({ onChange, poState }) => {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [error, setError] = useState<any>({ title: "", message: "" });
  const [data, setData] = useState<any[]>([]);
  const [payload, setPayload] = useState<any>({});
  const [reportRender, setReportRender] = useState<any>(null);
  const [isContinuable, setIsContinuable] = useState(false);
  const [ignoredRows, setIgnoredRows] = useState<any>({});
  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    onChange(info) {
      if (info.fileList.length === 0) {
        setFileList([]);
        setIsContinuable(false);
      }

      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };
  const validateColumns = (row: { [key: string]: any }): string[] => {
    return REQUIRED_COLUMNS.filter((column) => !(column in row));
  };
  const findEmptyRequiredValues = (
    data: Array<{ [key: string]: any }>
  ): any[] => {
    let emptyDetails: any[] = [];

    data.forEach((row, index) => {
      REQUIRED_COLUMNS.forEach((column) => {
        if (!(column in row) || row[column] === null || row[column] === "") {
          emptyDetails.push({ row: index + 2, column: column });
        }
      });
    });

    return emptyDetails;
  };

  const readFile = useCallback((file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const workbook = XLSX.read(e.target.result, {
          type: "binary",
          cellDates: true,
          raw: true,
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
        });
        setData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      message.error("Error reading the file.");
    }
  }, []);

  useEffect(() => {
    if (fileList.length === 0) {
      return () => {};
    }
    readFile(fileList[0]);
  }, [fileList]);

  useEffect(() => {
    setIsContinuable(false);
    if (fileList.length === 0) {
      setReportRender(null);
      return () => {};
    }

    if (data.length === 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message="No data was found in the file, please validate that mappings are present in the first sheet."
          showIcon
        />
      );
      return () => {};
    }

    setReportRender(null);

    const missingColumns = validateColumns(data[0]);

    if (missingColumns.length > 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message={`Missing required columns:`}
          description={`${missingColumns.join(", ")}`}
          showIcon
        />
      );
      return () => {};
    }

    const emptyDetails: { row: number; column: string }[] =
      findEmptyRequiredValues(data);

    if (emptyDetails?.length > 0) {
      const missingRequiredValuesMap: { [key: string]: number[] } = {};
      emptyDetails.forEach((detail) => {
        if (!missingRequiredValuesMap[detail.column]) {
          missingRequiredValuesMap[detail.column] = [];
        }
        missingRequiredValuesMap[detail.column].push(detail.row);
      });
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          description={
            <div>
              {Object.entries(missingRequiredValuesMap).map(
                ([column, rows]) => (
                  <div key={column}>
                    <strong>{titleCase(column)}:</strong>{" "}
                    <Tooltip
                      placement="right"
                      title={
                        <div>
                          <div>Rows: {rows.join(", ")}</div>
                        </div>
                      }
                    >
                      <span className="text-blue-500">
                        Rows <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                )
              )}
            </div>
          }
          message={
            <div>
              <div>Some required values are missing in the file.</div>
            </div>
          }
          showIcon
        />
      );
      return () => {};
    }

    const ignoredRows = { product: 0, vendor: 0, warehouse: 0, rows: [] };

    const invalidProducts = new Set<string>();
    const invalidSkus = [];
    const invalidVendors = new Set<string>();

    let mappedData = [];

    const vendorMap = {};

    for (let row of data) {
      const { "Vendor Code": vendorCode, "Product Sku Code": productSkuCode } =
        row;
      const tokens = productSkuCode.split("_");
      const size = tokens.pop();
      const productCode = tokens.join("_");
      vendorMap[vendorCode] = {
        ...(vendorMap[vendorCode] || {}),
        [productCode]: {
          "2": null,
          "3": null,
          "4": null,
          "5": null,
          "6": null,
          "7": null,
          "8": null,
          "9": null,
          "10": null,
          "11": null,
          "12": null,
          "13": null,
          ...(vendorMap[vendorCode]?.[productCode] || {}),
          [size]: row["Qty"],
          "Expiry At": row["Expiry Date"],
          "Start Date": row["Start Date"],
          "Order Date": row["Order Date"],
          Warehouse: row["Warehouse"],
          Vendor: vendorCode,
          "Product Code": productCode,
        },
      };
    }

    // for (let vendorCode of Object.keys(vendorMap)) {
    //   const vendorMappedData = {};

    //   let ExpiryAt = "";
    //   let StartDate = "";
    //   let OrderDate = "";
    //   let Warehouse = "";
    //   let ProductCode = "";
    //   const vendorRows = vendorMap[vendorCode];
    //   const Vendor = vendorCode;
    //   const sizes = {};

    //   for (let row of vendorRows) {
    //     if (row["Expiry Date"] && !ExpiryAt) {
    //       ExpiryAt = row["Expiry Date"];
    //     }
    //     if (row["Start Date"] && !StartDate) {
    //       StartDate = row["Start Date"];
    //     }
    //     if (row["Order Date"] && !OrderDate) {
    //       OrderDate = row["Order Date"];
    //     }
    //     if (row["Warehouse"] && !Warehouse) {
    //       Warehouse = row["Warehouse"];
    //     }
    //     if (row["Product Sku Code"] && !ProductCode) {
    //       ProductCode = row["Product Sku Code"];
    //       console.log("ProductCode", ProductCode);
    //       if (!vendorMappedData[ProductCode]) {
    //         vendorMappedData[ProductCode] = {};
    //       }
    //     }
    //   }

    //   console.log()

    //   for (let row of vendorRows) {
    //     const tokens = ProductCode.split("_");
    //     const size = tokens.pop();
    //     const productCode = tokens.join("_");
    //     vendorMappedData[productCode][size] = row["Qty"];
    //   }

    //   console.log("vendorMappedData", vendorMappedData);
    // }

    // for (let row of data) {
    //   const {
    //     "Product Sku Code": productSkuCode,
    //     "Vendor Code": vendorCode,
    //     Warehouse: warehouseCode,
    //     "Order Date": orderDate,
    //     "Expiry Date": expiryDate,
    //     "Start Date": startDate,
    //     Qty: qty,
    //   } = row;

    //   const tokens = productSkuCode.split("_");

    //   const size = tokens.pop();

    //   const productCode = tokens.join("_");

    //   if (mappedData.some((data) => data["Product Code"] === productCode)) {
    //     const existingRow = mappedData.find(
    //       (data) => data["Product Code"] === productCode
    //     );

    //     if (existingRow) {
    //       existingRow[size] = qty;
    //     }
    //   } else {
    //     mappedData.push({
    //       "Product Code": productCode,
    //       Vendor: vendorCode,
    //       Warehouse: warehouseCode,
    //       "Order Date": orderDate,
    //       "Expiry At": expiryDate,
    //       "Start Date": startDate,
    //       "2": null,
    //       "3": null,
    //       "4": null,
    //       "5": null,
    //       "6": null,
    //       "7": null,
    //       "8": null,
    //       "9": null,
    //       "10": null,
    //       "11": null,
    //       "12": null,
    //       "13": null,
    //       ...{ [size]: qty },
    //     });
    //   }
    // }

    mappedData = Object.values(vendorMap).flatMap((vendor) =>
      Object.values(vendor)
    );

    console.log("mappedData", mappedData);

    const payload: any = {};

    validateWarehouses(
      Array.from(
        new Set(data.map((row) => row["Warehouse"]).filter((value) => !!value))
      )
    ).then((response) => {
      for (let row of mappedData) {
        const {
          "Product Code": productCode,
          Vendor: vendorCode,
          Warehouse: warehouseCode,
          "Expiry At": expiryAt,
          "Start Date": startDate,
          "Order Date": orderDate,
          ...sizes
        } = row;
        const product = poState.products.data.find((product) =>
          product._id.match(new RegExp(`^${productCode.trim()}$`, "i"))
        );

        if (!product) {
          invalidProducts.add(productCode);
        }
        const vendor = poState.vendors.data.find(
          (vendor) => vendor.code.toLowerCase() === vendorCode.toLowerCase()
        );
        if (!vendor) {
          invalidVendors.add(vendorCode);
        }
        const isWarehouseValid = response[warehouseCode];

        if (!product || !vendor || !isWarehouseValid) {
          ignoredRows.product += !product ? 1 : 0;
          ignoredRows.vendor += !vendor ? 1 : 0;
          ignoredRows.warehouse += !isWarehouseValid ? 1 : 0;
          ignoredRows.rows.push(row);
          continue;
        }

        let skus = Object.keys(sizes).map((size) => {
          if (row[size] === 0) return;
          const sku = product.skus.find((sku) =>
            sku.skuCode.endsWith(`_${size}`)
          );
          if (!sku && row[size] !== null && row[size] !== 0) {
            invalidSkus.push({ productCode, size });
          }
          return { ...sku, quantity: row[size] };
        });
        skus = skus.filter((sku) => !!sku?._id);
        payload[vendorCode] = {
          vendor: vendor,
          skus: [...(payload[vendorCode]?.skus || []), ...skus],
          rows: [...(payload[vendorCode]?.rows || []), row],
          warnings: [],
        };
      }

      setIgnoredRows(ignoredRows);

      for (let key in payload) {
        const { rows } = payload[key];

        const invalidWarehouseRows = rows.filter(
          (row) => !response[row["Warehouse"]]
        );

        if (invalidWarehouseRows.length) {
          payload[key].warnings.push(
            `${invalidWarehouseRows.length} rows ignored due to invalid warehouse`
          );
        }
        const nonZeroRows = getNonZeroRows(rows);
        const zeroRowsLength = rows.length - nonZeroRows.length;
        if (zeroRowsLength) {
          payload[key].warnings.push(
            `${
              rows.length - nonZeroRows.length
            } rows ignored due to 0 total quantity`
          );
        }
        const significantRows = getSignificantRows(rows);
        const nonSignificantRowsLength =
          nonZeroRows.length - significantRows.length;
        if (nonSignificantRowsLength) {
          payload[key].warnings.push(
            `${
              nonZeroRows.length - significantRows.length
            } rows ignored due to less than 4 total quantity`
          );
        }
        payload[key].significantRows = significantRows;
      }

      if (
        invalidProducts.size > 0 ||
        invalidVendors.size > 0 ||
        invalidSkus.length > 0
      ) {
        setReportRender(
          <div>
            {invalidProducts.size > 0 && (
              <Alert
                className="mt-2"
                type="error"
                message={`Invalid Products:`}
                description={`${Array.from(invalidProducts).join(", ")}`}
                showIcon
              />
            )}
            {invalidVendors.size > 0 && (
              <Alert
                className="mt-2"
                type="error"
                message={`Invalid Vendors:`}
                description={`${Array.from(invalidVendors).join(", ")}`}
                showIcon
              />
            )}
            {invalidSkus.length > 0 && (
              <Alert
                className="mt-2"
                type="error"
                message={`Invalid Skus:`}
                description={
                  <div>
                    {invalidSkus.map(({ productCode, size }) => (
                      <div key={productCode}>
                        <strong>{productCode}</strong>: {size}
                      </div>
                    ))}
                  </div>
                }
                showIcon
              />
            )}
          </div>
        );
      }
      setIsContinuable(true);

      setPayload({ payload, ignoredRows });
    });
  }, [data, fileList, poState]);

  return (
    <div>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      {error.title && (
        <Alert
          message={error.title}
          description={error.message}
          type="error"
          showIcon
        />
      )}
      <Divider className="my-2" />
      <div className="flex justify-between ">
        <Button disabled>Previous</Button>
        <Button
          type="primary"
          onClick={() => onChange(payload)}
          disabled={fileList.length === 0 || !isContinuable}
          loading={uploading}
        >
          Next
        </Button>
      </div>
      <div>{reportRender}</div>
    </div>
  );
};

const SIZE_COLUMNS: any = [
  {
    title: "Size: 2",
    dataIndex: "2",
    key: "2",
    align: "right",
  },
  {
    title: "Size: 3",
    dataIndex: "3",
    key: "3",
    align: "right",
  },
  {
    title: "Size: 4",
    dataIndex: "4",
    key: "4",
    align: "right",
  },
  {
    title: "Size: 5",
    dataIndex: "5",
    key: "5",
    align: "right",
  },
  {
    title: "Size: 6",
    dataIndex: "6",
    key: "6",
    align: "right",
  },
  {
    title: "Size: 7",
    dataIndex: "7",
    key: "7",
    align: "right",
  },
  {
    title: "Size: 8",
    dataIndex: "8",
    key: "8",
    align: "right",
  },
  {
    title: "Size: 9",
    dataIndex: "9",
    key: "9",
    align: "right",
  },
  {
    title: "Size: 10",
    dataIndex: "10",
    key: "10",
    align: "right",
  },
  {
    title: "Size: 11",
    dataIndex: "11",
    key: "11",
    align: "right",
  },
  {
    title: "Size: 12",
    dataIndex: "12",
    key: "12",
    align: "right",
  },
  {
    title: "Size: 13",
    dataIndex: "13",
    key: "13",
    align: "right",
  },

  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    align: "right",
    sorter: (a: any, b: any) => a.total - b.total,
  },
];

const ApproveProcess: React.FC<any> = ({
  data,
  onChange,
  onPrevious,
  loading,
  ignoredRows,
}) => {
  const purchaseWarehouseState = useSelector(
    (state: Store) => state.purchaseWarehouse
  );
  const clientState = useSelector((state: Store) => state.client);
  const totalToBeCreatedRef = useRef(0);
  const productCodeSearchMemo = useMemo(() => {
    return data
      ? (Object.entries(data) as any)
          .flatMap(([vendorCode, { skus }]) =>
            skus.map((sku) => sku.productCode)
          )
          .filter((value, index, self) => self.indexOf(value) === index)
          .map((value) => ({ text: value, value }))
      : [];
  }, [data]);

  totalToBeCreatedRef.current = 0;
  const handleDownloadExcelWithIgnoredRows = () => {
    const headers = ["Product Code", "Vendor"];
    const ws = XLSX.utils.json_to_sheet(ignoredRows.rows, { header: headers });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Ignored Rows");
    XLSX.writeFile(wb, "ignored-rows.xlsx");
  };
  return (
    <div>
      <div className="text-lg font-semibold">Review Purchase Orders</div>
      <Collapse className="mt-4">
        {(Object.entries(data) as any).map(
          ([vendorCode, { vendor, skus, rows, significantRows, warnings }]) => {
            if (significantRows.length === 0) return null;
            totalToBeCreatedRef.current += 1;
            return (
              <Collapse.Panel
                key={vendorCode}
                header={
                  <div className="flex justify-between items-center">
                    <div>
                      <strong>{vendor.name}</strong> ({vendor.code})
                    </div>
                    <div className="flex gap-x-2">
                      <span className="text-sm">
                        {significantRows.length} Product
                        {significantRows.length > 1 ? "s" : ""}
                      </span>
                      {/* Total Quantity */}
                      <span>
                        <span className="text-sm">Total Quantity: </span>
                        <span className="font-semibold">
                          {significantRows.reduce(
                            (acc: number, row: any) =>
                              acc +
                              Object.keys(row)
                                .filter((key) => !isNaN(+key))
                                .reduce((acc, key) => acc + row[key], 0),
                            0
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                }
              >
                <div className="flex flex-col gap-y-2">
                  {warnings.map((warning: string, index: number) => (
                    <Alert
                      key={index}
                      type="warning"
                      showIcon
                      message={warning}
                    />
                  ))}
                </div>
                <Table
                  className="mt-2"
                  size="small"
                  pagination={false}
                  dataSource={significantRows}
                  columns={[
                    {
                      title: "Product",
                      dataIndex: "Product Code",
                      key: "product",
                      sorter: (a: any, b: any) =>
                        a["Product Code"].localeCompare(b["Product Code"]),
                      filters: productCodeSearchMemo,
                      filterSearch: true,
                      onFilter: (value: any, record: any) =>
                        record["Product Code"] === value,
                    },
                    ...SIZE_COLUMNS,
                  ]}
                />
              </Collapse.Panel>
            );
          }
        )}
      </Collapse>

      <div className="flex flex-col gap-y-2">
        <Alert
          className="mt-2"
          type="info"
          showIcon
          message={`${totalToBeCreatedRef.current} Purchase Orders to be created`}
        />
        {ignoredRows?.rows.length > 0 && (
          <Alert
            type="warning"
            showIcon
            message={`${ignoredRows.rows.length} rows ignored`}
            description={
              <div>
                {ignoredRows.product.length > 0 && (
                  <div>
                    {ignoredRows.product} Product
                    {ignoredRows.product > 1 ? "s" : ""} not found
                  </div>
                )}
                {ignoredRows.vendor > 0 && (
                  <div>
                    {ignoredRows.vendor} Vendor
                    {ignoredRows.vendor > 1 ? "s" : ""} not found
                  </div>
                )}
                {ignoredRows.warehouse > 0 && (
                  <div>
                    {ignoredRows.warehouse} Warehouse
                    {ignoredRows.warehouse > 1 ? "s" : ""} not found
                  </div>
                )}
              </div>
            }
            action={
              <div>
                {" "}
                <Button
                  size="small"
                  type="link"
                  onClick={() => handleDownloadExcelWithIgnoredRows()}
                >
                  Download Excel with Ignored Rows
                </Button>
              </div>
            }
          />
        )}
      </div>
      <Divider className="my-2" />

      <div className="flex justify-between">
        <Button onClick={onPrevious}>Previous</Button>
        <Button
          type="primary"
          onClick={onChange}
          loading={loading}
          disabled={!clientState.current}
        >
          Approve & Process
        </Button>
      </div>
    </div>
  );
};

const Report = (props: any) => {
  const { data } = props;

  const { failedPurchaseOrders, successfulPurchaseOrders } = data;
  return (
    failedPurchaseOrders &&
    successfulPurchaseOrders && (
      <div className="">
        <div className="text-lg font-semibold">Report</div>
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <div>Failed Purchase Orders</div>
            <Tag color="red">{failedPurchaseOrders.length}</Tag>
          </div>
          <Collapse className="mt-4">
            {failedPurchaseOrders.map(
              ({ vendor, skus, rows, significantRows, warnings }) => (
                <Collapse.Panel
                  key={vendor.code}
                  header={
                    <div className="flex justify-between items-center">
                      <div>
                        <strong>{vendor.name}</strong> ({vendor.code})
                      </div>
                      <div>
                        <span className="text-sm">
                          {significantRows.length} Product
                          {significantRows.length > 1 ? "s" : ""}
                        </span>
                      </div>
                    </div>
                  }
                >
                  <div className="flex flex-col gap-y-2">
                    {warnings.map((warning: string, index: number) => (
                      <Alert
                        key={index}
                        type="warning"
                        showIcon
                        message={warning}
                      />
                    ))}
                  </div>
                  <Table
                    className="mt-2"
                    size="small"
                    pagination={false}
                    dataSource={significantRows}
                    columns={[
                      {
                        title: "Product",
                        dataIndex: "Product Code",
                        key: "product",
                        sorter: (a: any, b: any) =>
                          a["Product Code"].localeCompare(b["Product Code"]),
                      },
                      ...SIZE_COLUMNS,
                    ]}
                  />
                </Collapse.Panel>
              )
            )}
          </Collapse>
        </div>
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <div>Successful Purchase Orders</div>
            <Tag color="green">{successfulPurchaseOrders.length}</Tag>
          </div>
          <Collapse className="mt-4">
            {successfulPurchaseOrders.map(
              ({ vendor, skus, rows, significantRows, warnings }) => (
                <Collapse.Panel
                  key={vendor.code}
                  header={
                    <div className="flex justify-between items-center">
                      <div>
                        <strong>{vendor.name}</strong> ({vendor.code})
                      </div>
                      <div>
                        <span className="text-sm">
                          {significantRows.length} Product
                          {significantRows.length > 1 ? "s" : ""}
                        </span>
                      </div>
                    </div>
                  }
                >
                  <div className="flex flex-col gap-y-2">
                    {warnings.map((warning: string, index: number) => (
                      <Alert
                        key={index}
                        type="warning"
                        showIcon
                        message={warning}
                      />
                    ))}
                  </div>
                  <Table
                    className="mt-2"
                    size="small"
                    pagination={false}
                    dataSource={significantRows}
                    columns={[
                      {
                        title: "Product",
                        dataIndex: "Product Code",
                        key: "product",
                        sorter: (a: any, b: any) =>
                          a["Product Code"].localeCompare(b["Product Code"]),
                      },
                      ...SIZE_COLUMNS,
                    ]}
                  />
                </Collapse.Panel>
              )
            )}
          </Collapse>
        </div>
      </div>
    )
  );
};

export default function Bulk() {
  const dispatch = useDispatch();
  const poState = useSelector((state: Store) => state.po);
  const [step, setStep] = useState(0);
  const [isBlocking, setIsBlocking] = useState(true);
  const [uploadResult, setUploadResult] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<any>();
  const [ignoredRows, setIgnoredRows] = useState<any[]>([]);
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Bulk Process" },
  ]);
  useEffect(() => {
    window.onbeforeunload = isBlocking ? () => true : undefined;
  }, [isBlocking]);
  useEffect(() => {
    handleLoadDependencies();
  }, []);
  const handleLoadDependencies = async () => {
    try {
      dispatch(poActions.fetchProducts());
      dispatch(poActions.fetchVendors());
    } catch (error) {
      throw error;
    }
  };
  const handleApprove = async () => {
    try {
      setLoading(true);
      const report = await service.bulkRegister({ ...uploadResult });
      setReport(report);
      setStep(2);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const render = () => {
    switch (step) {
      case 0: {
        return (
          <UploadRender
            poState={poState}
            onChange={({ payload, ignoredRows }) => {
              console.log(ignoredRows);

              setStep(1);
              setUploadResult(payload);
              setIgnoredRows(ignoredRows);
            }}
          />
        );
      }
      case 1: {
        return (
          <ApproveProcess
            data={uploadResult}
            ignoredRows={ignoredRows}
            onChange={handleApprove}
            onPrevious={() => setStep(0)}
            loading={loading}
          />
        );
      }
      case 2: {
        return <Report data={report} onPrevious={() => setStep(0)} />;
      }
    }
  };
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New Manual Bulk Purchase Orders
      </div>
      <div className="mt-8">
        <Steps
          progressDot
          size="small"
          current={step}
          onChange={() => {}}
          items={[
            {
              title: "Upload File",
            },
            {
              title: "Approve & Process",
            },
            {
              title: "Review Report",
            },
          ]}
        />
      </div>
      <div className="bg-white rounded-lg mt-2 shadow px-2 py-2">
        {render()}
      </div>
    </div>
  );
}
