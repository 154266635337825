import { Button, Col, Row, Table } from "antd";
import skuService from "../../../services/sku";
import poService from "../../../services/po";
import { Typography } from "antd";
import { useEffect, useState } from "react";
import saveAs from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import * as XLSX from "xlsx"; // Added import
const { Title } = Typography;
function PendingPOReport() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    handleLoad();
  }, []);

  const handleLoad = async () => {
    try {
      setLoading(true);
      const data = await poService.getPendingPOReport();
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadXlsx = () => {
    // Define headers matching AntD table
    const headers = [
      "PO ID",
      "Date",
      "Created By",
      "SKU Code",
      "Size",
      "Vendor",
      "Received Quantity",
      "Rejected Quantity",
      "Pending Quantity",
      "Ordered Quantity",
      "Age (Days)",
    ];

    // Transform data to match headers
    const transformedData = data.map((item) => ({
      "PO ID": item.poId,
      Date: dayjs(item.date).format("DD-MM-YYYY"),
      "Created By": item.createdBy,
      "SKU Code": item.skuCode,
      Size: item.size,
      Vendor: item.vendor,
      "Received Quantity": item.receivedQuantity,
      "Rejected Quantity": item.rejectedQuantity,
      "Pending Quantity": item.pendingQuantity,
      "Ordered Quantity": item.orderedQuantity,
      "Age (Days)": item.age,
    }));

    const worksheet = XLSX.utils.json_to_sheet(transformedData, {
      header: headers,
    });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "PendingPOReport");
    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "PendingPOReport.xlsx");
  };
  const columns = [
    { title: "PO ID", dataIndex: "poId", key: "poId" },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("DD-MM-YYYY"),
    },
    { title: "Created By", dataIndex: "createdBy", key: "createdBy" },
    { title: "SKU Code", dataIndex: "skuCode", key: "skuCode" },
    { title: "Size", dataIndex: "size", key: "size" },
    { title: "Vendor", dataIndex: "vendor", key: "vendor" },
    {
      title: "Received Quantity",
      dataIndex: "receivedQuantity",
      key: "receivedQuantity",
    },
    {
      title: "Rejected Quantity",
      dataIndex: "rejectedQuantity",
      key: "rejectedQuantity",
    },
    {
      title: "Pending Quantity",
      dataIndex: "pendingQuantity",
      key: "pendingQuantity",
    },
    {
      title: "Ordered Quantity",
      dataIndex: "orderedQuantity",
      key: "orderedQuantity",
    },
    { title: "Age (Days)", dataIndex: "age", key: "age" },
  ];
  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>Pending PO Report</Title>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <div className="mt-4">
        <div className="mb-3">
          <Button
            type="primary"
            onClick={() => handleLoad()}
            loading={loading}
            icon={<DownloadOutlined />}
          >
            Download Report
          </Button>
          {data.length > 0 && ( // Added conditional rendering
            <Button
              type="default"
              onClick={handleDownloadXlsx}
              icon={<DownloadOutlined />}
              className="ml-2"
            >
              Download as XLSX
            </Button>
          )}
        </div>
        <Table dataSource={data} columns={columns} />
      </div>
    </div>
  );
}

export default PendingPOReport;
