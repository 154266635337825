import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Space,
  Table,
  Tabs,
  Typography,
} from "antd";
import ExcelJS from "exceljs";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import reportService from "../../services/report";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import _ from "lodash";
import { titleCase } from "title-case";
import OMSWarehouseSelect from "../../components/OMSWarehouseSelect";
import { useNavigate } from "react-router-dom";
import { BASE_PATH } from ".";

const { Title } = Typography;

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export default function Report(props: any) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [duration, setDuration] = useState<{
    start: Dayjs | null;
    end: Dayjs | null;
  }>({ start: null, end: null });
  const [loading, setLoading] = useState(false);
  const [preset, setPreset] = useState<"" | "7" | "15" | "30" | "45">("30");
  const [data, setData] = useState<any>();
  const warehouseState = useSelector((state: Store) => state.warehouse);
  useEffect(() => {
    form.setFieldsValue({
      duration: [dayjs().subtract(30, "day"), dayjs()],
    });
  }, []);
  const handleGenerateReport = useCallback(async (values: any) => {
    try {
      setLoading(true);
      const data = await reportService.generatePurchaseOrderReport(
        values.warehouse,
        values.duration[0].toISOString(),
        values.duration[1].toISOString(),
        values.trafficIndex
      );
      setData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getProductCodeSearch = useCallback(() => {
    console.log("Called getProductCodeSearch");

    let search = [];
    if (data) {
      search = data.scaledROASByProduct
        .map((row: any) => ({
          text: row["Product Code"],
          value: row["Product Code"],
        }))
        .sort((a: any, b: any) => a.text.localeCompare(b.text));
    }
    return search;
  }, [data]);
  const getVendorSearch = useCallback(() => {
    console.log("Called getVendorSearch");

    let search = [];

    if (data) {
      search = _.uniqWith(
        data.reOrderQuantityByProductBySize
          .map((row: any) => ({
            text: row["Vendor"],
            value: row["Vendor"],
          }))
          .sort((a: any, b: any) => a.text.localeCompare(b.text)),
        _.isEqual
      );
    }
    return search;
  }, [data]);
  const getWarehouseSearch = useCallback(() => {
    console.log("Called getWarehouseSearch");

    let search = [];

    if (data) {
      search = _.uniqWith(
        data.reOrderQuantityByProductBySize
          .map((row: any) => ({
            text: titleCase(row["Warehouse"]),
            value: row["Warehouse"],
          }))
          .sort((a: any, b: any) => a.text.localeCompare(b.text)),
        _.isEqual
      );
    }
    return search;
  }, [data]);

  const handleConvertToExcelAndDownload = useCallback(async () => {
    try {
      setLoading(true);
      const workbook = new ExcelJS.Workbook();

      // ===============================
      // Sheet 1: ROAS with Re-order Quantity
      // ===============================
      const sheet1 = workbook.addWorksheet("ROAS with Re-order Quantity");

      // Define column data types
      const columnDataTypes = {
        "Style ID": "string",
        Brand: "string",
        "Master Category": "string",
        "Article Type": "string",
        "Traffic Index": "string",
        "New Article Base Style": "string",
        "No. of Days": "number",
        "Product Code": "string",
        "Auto Profiling": "string",
        "Final Profiling": "string",
        "Liquidation Status": "string",
        MRP: "number",
        Color: "string",
        "Total Returns": "number",
        "Return Percentage": "number",
        "Order Requirement": "string",
        "Total OMS Sales": "number",
        "Total SJIT Sales": "number",
        "Total Sales": "number",
        "Total OMS Inventory": "number",
        "Total SJIT Inventory": "number",
        "Rate of Average Sales": "number",
        "Required Quantity": "number",
        "Pending Quantity": "number",
        "Available Quantity": "number",
        "Reorder Quantity": "number",
        Warehouse: "string",
        "Purchase Price": "number",
        "EVENT ASP": "number",
        "BAU ASP": "number",
        "Average Discount": "number",
        "Average Discount %": "number",
      };

      // Add headers
      const headers = Object.keys(data.scaledROASByProduct[0] || {});
      const headerRow = sheet1.addRow(headers);

      // Style the header row: Background and Font
      headerRow.eachCell((cell, colNumber) => {
        // Set background color
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCFFCC" }, // Light green background
        };
        // Set font style
        cell.font = {
          bold: true,
          color: { argb: "FF000000" }, // Black text
        };
        // Set alignment based on data type
        const header = cell.value as any;
        const dataType = columnDataTypes[header];
        if (dataType === "number") {
          cell.alignment = { horizontal: "right" };
        } else {
          cell.alignment = { horizontal: "left" };
        }
      });

      // Add data rows
      data.scaledROASByProduct.forEach((item) => {
        const row = headers.map((header) => item[header]);
        sheet1.addRow(row);
      });

      // Set column widths
      sheet1.columns.forEach((column) => {
        column.width = 20; // Adjust as needed
      });

      // Apply conditional background and text styling to "Profile" column
      const profileHeader = "Profile"; // Change to your actual header name
      const profileColIndex = headers.indexOf(profileHeader) + 1; // 1-based index

      if (profileColIndex > 0) {
        // Define a mapping for profile styles
        const profileStyles = {
          Core: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF008000" },
            }, // Green background
            font: { color: { argb: "FFFFFFFF" }, bold: true }, // White text
          },
          Bestsellers: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF00" },
            }, // Yellow background
            font: { color: { argb: "FF000000" }, bold: true }, // Black text
          },
          "Low Selling": {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFA500" },
            }, // Orange background
            font: { color: { argb: "FFFFFFFF" }, bold: true }, // White text
          },
          Discontinued: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFF0000" },
            }, // Red background
            font: { color: { argb: "FFFFFFFF" }, bold: true }, // White text
          },
          New: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FF0000FF" },
            }, // Blue background
            font: { color: { argb: "FFFFFFFF" }, bold: true }, // White text
          },
        };

        sheet1
          .getColumn(profileColIndex)
          .eachCell({ includeEmpty: false }, (cell, rowNumber) => {
            if (rowNumber === 1) return; // Skip header row

            const cellValue = String(cell.value).trim();
            const style = profileStyles[cellValue];

            if (style) {
              cell.fill = style.fill;
              cell.font = style.font;
            } else {
              // Optional: Default styling for other values
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFCCCCCC" },
              }; // Light gray background
              cell.font = { color: { argb: "FF000000" } }; // Black text
            }
          });
      } else {
        console.warn(`Column with header "${profileHeader}" not found.`);
      }

      // ===============================
      // Additional Formatting Changes
      // ===============================

      // 1. Apply conditional formatting to 'Auto Profiling' column
      const autoProfilingHeader = "Auto Profiling"; // Change to your actual header name
      const autoProfilingColIndex = headers.indexOf(autoProfilingHeader) + 1; // 1-based index

      if (autoProfilingColIndex > 0) {
        // Define a mapping for Auto Profiling styles
        const autoProfilingStyles = {
          Discontinued: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFF4C7C3" }, // Light Red background
            },
            font: { color: { argb: "FF9C0006" }, bold: true }, // Dark Red text
          },
          "Low Seller": {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFE599" }, // Orange background
            },
            font: { color: { argb: "FF643200" }, bold: true }, // Brown text
          },
          Core: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFC6EFCE" }, // Light Green background
            },
            font: { color: { argb: "FF006100" }, bold: true }, // Green text
          },
          "Best Seller": {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFBDD7EE" }, // Light Blue background
            },
            font: { color: { argb: "FF002060" }, bold: true }, // Blue text
          },
          New: {
            fill: {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFFFFF00" }, // Yellow background
            },
            font: { color: { argb: "FF000000" }, bold: true }, // Black text
          },
        };

        sheet1
          .getColumn(autoProfilingColIndex)
          .eachCell({ includeEmpty: false }, (cell, rowNumber) => {
            if (rowNumber === 1) return; // Skip header row

            const cellValue = String(cell.value).trim();
            const style = autoProfilingStyles[cellValue];

            if (style) {
              cell.fill = style.fill;
              cell.font = style.font;
            } else {
              // Optional: Default styling for other values
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFCCCCCC" },
              }; // Light gray background
              cell.font = { color: { argb: "FF000000" } }; // Black text
            }
          });
      } else {
        console.warn(`Column with header "${autoProfilingHeader}" not found.`);
      }

      // 2. Apply conditional formatting to 'Return Percentage' column
      const returnPercentageHeader = "Return Percentage"; // Change to your actual header name
      const returnPercentageColIndex =
        headers.indexOf(returnPercentageHeader) + 1; // 1-based index

      if (returnPercentageColIndex > 0) {
        sheet1
          .getColumn(returnPercentageColIndex)
          .eachCell({ includeEmpty: false }, (cell, rowNumber) => {
            if (rowNumber === 1) return; // Skip header row

            const cellValue = parseFloat(cell.value as string);
            if (isNaN(cellValue)) return; // Skip if not a number

            let fillColor = null;

            if (cellValue <= 20) {
              fillColor = "FF90EE90"; // Light Green
            } else if (cellValue <= 30) {
              fillColor = "FFFFFFE0"; // Light Yellow
            } else if (cellValue <= 40) {
              fillColor = "FFFFD700"; // Dark Yellow
            } else if (cellValue > 50) {
              fillColor = "FFFF0000"; // Red
            }

            if (fillColor) {
              cell.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: fillColor },
              };
              cell.font = {
                color: { argb: "FF000000" }, // Black text
              };
            }
          });
      } else {
        console.warn(
          `Column with header "${returnPercentageHeader}" not found.`
        );
      }

      // ===============================
      // Sheet 2: Scaled Size Distribution
      // ===============================
      const sheet2 = workbook.addWorksheet("Scaled Size Distribution");
      const sheet2Headers = [
        "Product Code",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
      ];
      const sheet2HeaderRow = sheet2.addRow(sheet2Headers);

      // Style the header row for Sheet 2
      sheet2HeaderRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCFFCC" }, // Light green background
        };
        cell.font = {
          bold: true,
          color: { argb: "FF000000" }, // Black text
        };
        // Assuming all headers in Sheet 2 are strings except "Product Code"
        const header = cell.value;
        if (header === "Product Code") {
          cell.alignment = { horizontal: "left" };
        } else {
          cell.alignment = { horizontal: "left" }; // Adjust if needed
        }
      });

      data.scaledDistributionByProduct.forEach((row) => {
        const dataRow = [
          row["Product Code"],
          row["2"],
          row["3"],
          row["4"],
          row["5"],
          row["6"],
          row["7"],
          row["8"],
          row["9"],
          row["10"],
          row["11"],
          row["12"],
          row["13"],
        ];
        sheet2.addRow(dataRow);
      });

      // Set column widths for Sheet 2
      sheet2.columns.forEach((column, index) => {
        column.width = index === 0 ? 25 : 15; // Example: first column wider
      });

      // ===============================
      // Sheet 3: SKU-wise Re-order Quantity
      // ===============================
      const sheet3 = workbook.addWorksheet("SKU-wise Re-order Quantity");
      const sheet3Headers = [
        "Product Code",
        "Vendor",
        "Warehouse",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "Expiry At",
      ];
      const sheet3HeaderRow = sheet3.addRow(sheet3Headers);

      // Style the header row for Sheet 3
      sheet3HeaderRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCFFCC" }, // Light green background
        };
        cell.font = {
          bold: true,
          color: { argb: "FF000000" }, // Black text
        };
        // Assuming all headers in Sheet 3 are strings except specific columns
        const header = cell.value as any;
        if (
          [
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "Expiry At",
          ].includes(header)
        ) {
          cell.alignment = { horizontal: "right" };
        } else {
          cell.alignment = { horizontal: "left" };
        }
      });

      data.reOrderQuantityByProductBySize.forEach((row) => {
        const dataRow = [
          row["Product Code"],
          row["Vendor"],
          row["Warehouse"],
          row["2"],
          row["3"],
          row["4"],
          row["5"],
          row["6"],
          row["7"],
          row["8"],
          row["9"],
          row["10"],
          row["11"],
          row["12"],
          row["13"],
          row["Expiry At"]
            ? dayjs(row["Expiry At"]).format("MM/DD/YYYY")
            : dayjs().add(30, "days").format("MM/DD/YYYY"),
        ];
        sheet3.addRow(dataRow);
      });

      // Set column widths for Sheet 3
      sheet3.columns.forEach((column, index) => {
        if (index === 1) {
          column.width = 25; // Vendor column
        } else if (index === 2) {
          column.width = 25; // Warehouse column
        } else {
          column.width = 15; // Default width
        }
      });

      const sheet4 = workbook.addWorksheet("Re-order Quantity By SKU Code");

      const sheet4Headers = [
        "Product Code",
        "SKU Code",
        "Required Quantity",
        "Pending Quantity",
        "OMS Inventory",
        "SJIT Inventory",
        "Reorder Quantity",
      ];
      const sheet4HeaderRow = sheet4.addRow(sheet4Headers);

      sheet4HeaderRow.eachCell((cell) => {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFCCFFCC" }, // Light green background
        };
        cell.font = {
          bold: true,
          color: { argb: "FF000000" }, // Black text
        };
        // Assuming all headers in Sheet 3 are strings except specific columns
        const header = cell.value as any;
        if (
          [
            "Required Quantity",
            "OMS Inventory",
            "SJIT Inventory",
            "Reorder Quantity",
          ].includes(header)
        ) {
          cell.alignment = { horizontal: "right" };
        } else {
          cell.alignment = { horizontal: "left" };
        }
      });

      data.reOrderCalcBySKUCode.forEach((row) => {
        const dataRow = [
          row["Product Code"],
          row["SKU Code"],
          row["Required Quantity"],
          row["OMS Inventory"],
          row["SJIT Inventory"],
          row["Reorder Quantity"],
        ];
        if (
          !row["Required Quantity"] &&
          !row["Pending Quantity"] &&
          !row["OMS Inventory"] &&
          !row["SJIT Inventory"] &&
          !row["Reorder Quantity"]
        ) {
          return;
        }
        sheet4.addRow(dataRow);
      });

      // ===============================
      // Generate and Download the Workbook
      // ===============================
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(blob, "report.xlsx");
    } catch (error) {
      console.error(error);
      // Optionally, display error to the user (e.g., toast notification)
    } finally {
      setLoading(false);
    }
  }, [data]);

  const productCodeSearchMemo = useMemo(getProductCodeSearch, [data]);
  const vendorSearchMemo = useMemo(getVendorSearch, [data]);
  const warehouseSearchMemo = useMemo(getWarehouseSearch, [data]);

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>Purchase Order Report Based on ROAS</Title>
        </Col>
        <Col></Col>
        <Col></Col>
      </Row>
      <div className="bg-white p-4 rounded-lg">
        <Form
          form={form}
          onFinish={(values) => handleGenerateReport(values)}
          initialValues={{ trafficIndex: 1 }}
        >
          <div className="flex gap-x-2">
            <Form.Item
              label="Duration"
              name="duration"
              rules={[
                {
                  required: true,
                  message: "Please select a duration",
                },
              ]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
            <Radio.Group buttonStyle="solid" value={preset}>
              {["7", "15", "30", "45"].map((value) => (
                <Radio.Button
                  value={value}
                  onClick={() => {
                    setData(undefined);
                    setPreset(value as any);
                    form.setFieldsValue({
                      duration: [
                        dayjs().subtract(Number(value), "day"),
                        dayjs(),
                      ],
                    });
                  }}
                >
                  Last {value} days
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>

          <Form.Item name="trafficIndex" label="Traffic Index">
            <InputNumber />
          </Form.Item>

          <Form.Item noStyle>
            <Button type="primary" htmlType="submit" loading={loading}>
              Generate Report
            </Button>
          </Form.Item>
        </Form>
      </div>
      {data && (
        <div className="mt-4">
          <Tabs
            defaultActiveKey="1"
            size="small"
            items={[
              {
                label: "Input Parameters",
                key: "1",
                children: (
                  <div className="pane p-4">
                    <div className="text-md font-light">Input Parameters</div>
                    <div className="mt-2">
                      {Object.keys(data.inputParameters).map((key) => {
                        return (
                          <div>
                            <span className="font-semibold">{key}: </span>
                            <span>{data.inputParameters[key]}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ),
              },
              {
                label: "SKU Inventory",
                key: "2",
                children: (
                  <div className="pane p-4">
                    <div className="text-md font-light">SKU Inventory</div>
                    <div>
                      <Table
                        className="mt-4"
                        size="small"
                        pagination={{ defaultPageSize: 20 }}
                        dataSource={data.inventoryRows}
                        columns={[
                          {
                            title: "Product",
                            dataIndex: "Product Code",
                            key: "product",
                            sorter: (a: any, b: any) =>
                              a["Product Code"].localeCompare(
                                b["Product Code"]
                              ),
                            filters: productCodeSearchMemo,
                            filterSearch: true,
                            onFilter: (value: any, record: any) =>
                              record["Product Code"] === value,
                          },
                          {
                            title: "SKU Code",
                            dataIndex: "SKU Code",
                            key: "skuCode",
                          },

                          {
                            title: "OMS Stock",
                            dataIndex: "OMS Stock",
                            key: "omsStock",
                          },
                          {
                            title: "SJIT Stock",
                            dataIndex: "SJIT Stock",
                            key: "sjitStock",
                          },
                        ]}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: "Product ROAS with Total Re-order Quantity",
                key: "3",
                children: (
                  <div className="pane p-4">
                    <div className="text-md font-light">
                      Product ROAS with Re-order Quantity
                    </div>
                    <div>
                      <Table
                        className="mt-4"
                        size="small"
                        pagination={{ defaultPageSize: 20 }}
                        dataSource={data.scaledROASByProduct}
                        columns={[
                          {
                            title: "Product",
                            dataIndex: "Product Code",
                            key: "product",
                            sorter: (a: any, b: any) =>
                              a["Product Code"].localeCompare(
                                b["Product Code"]
                              ),
                            filters: productCodeSearchMemo,
                            filterSearch: true,
                            onFilter: (value: any, record: any) =>
                              record["Product Code"] === value,
                          },
                          {
                            title: "Profile",
                            dataIndex: "Profile",
                            key: "profile",
                          },
                          {
                            title: "Myntra Style ID",
                            dataIndex: "Myntra Style ID",
                            key: "myntraStyleId",
                          },
                          {
                            title: "OMS Sales",
                            dataIndex: "Total OMS Sales",
                            key: "omsSales",
                            align: "right",
                          },
                          {
                            title: "SJIT Sales",
                            dataIndex: "Total SJIT Sales",
                            key: "sjitSales",
                            align: "right",
                          },
                          {
                            title: "Total OMS Inventory",
                            dataIndex: "Total OMS Inventory",
                            key: "totalOmsInventory",
                          },
                          {
                            title: "Total SJIT Inventory",
                            dataIndex: "Total SJIT Inventory",
                            key: "totalSjitInventory",
                          },
                          {
                            title: "ROAS",
                            dataIndex: "Rate of Average Sales",
                            key: "roas",
                            align: "right",
                            sorter: (a: any, b: any) =>
                              a["Rate of Average Sales"] -
                              b["Rate of Average Sales"],
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Required Quantity",
                            dataIndex: "Required Quantity",
                            align: "right",
                            key: "requiredQuantity",
                            sorter: (a: any, b: any) =>
                              a["Required Quantity"] - b["Required Quantity"],
                          },
                          {
                            title: "Pending Quantity",
                            dataIndex: "Pending Quantity",
                            align: "right",
                            key: "pendingQuantity",
                            sorter: (a: any, b: any) =>
                              a["Pending Quantity"] - b["Pending Quantity"],
                          },
                          {
                            title: "Available Quantity",
                            dataIndex: "Available Quantity",
                            align: "right",
                            key: "availableQuantity",
                            sorter: (a: any, b: any) =>
                              a["Available Quantity"] - b["Available Quantity"],
                          },
                          {
                            title: "Re-order Quantity",
                            dataIndex: "Reorder Quantity",
                            align: "right",
                            key: "reorderQuantity",
                            sorter: (a: any, b: any) =>
                              a["Reorder Quantity"] - b["Reorder Quantity"],
                          },
                        ]}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: "Product Scaled Size Distribution",
                key: "4",
                children: (
                  <div className="pane p-4">
                    <div className="text-md font-light">
                      Product Scaled Size Distribution
                    </div>
                    <div className="mt-2">
                      <Table
                        className="mt-4"
                        size="small"
                        pagination={{ defaultPageSize: 20 }}
                        dataSource={data.scaledDistributionByProduct}
                        columns={[
                          {
                            title: "Product",
                            dataIndex: "Product Code",
                            key: "product",
                            sorter: (a: any, b: any) =>
                              a["Product Code"].localeCompare(
                                b["Product Code"]
                              ),
                            filters: productCodeSearchMemo,
                            filterSearch: true,
                            onFilter: (value: any, record: any) =>
                              record["Product Code"] === value,
                          },
                          {
                            title: "Size: 2",
                            dataIndex: "2",
                            key: "2",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 3",
                            dataIndex: "3",
                            key: "3",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 4",
                            dataIndex: "4",
                            key: "4",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 5",
                            dataIndex: "5",
                            key: "5",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 6",
                            dataIndex: "6",
                            key: "6",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 7",
                            dataIndex: "7",
                            key: "7",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 8",
                            dataIndex: "8",
                            key: "8",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 9",
                            dataIndex: "9",
                            key: "9",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 10",
                            dataIndex: "10",
                            key: "10",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 11",
                            dataIndex: "11",
                            key: "11",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 12",
                            dataIndex: "12",
                            key: "12",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                          {
                            title: "Size: 13",
                            dataIndex: "13",
                            key: "13",
                            align: "right",
                            render: (value: number) => value?.toFixed(2),
                          },
                        ]}
                      />
                    </div>
                  </div>
                ),
              },
              {
                label: "Product SKU-wise Re-order Quantity",
                key: "5",
                children: (
                  <div className="pane p-4">
                    <div className="text-md font-light">
                      Product SKU-wise Re-order Quantity
                    </div>
                    <div className="mt-2">
                      <Table
                        className="mt-4"
                        size="small"
                        pagination={{ defaultPageSize: 20 }}
                        dataSource={data.reOrderQuantityByProductBySize}
                        columns={[
                          {
                            title: "Product",
                            dataIndex: "Product Code",
                            key: "product",
                            sorter: (a: any, b: any) =>
                              a["Product Code"].localeCompare(
                                b["Product Code"]
                              ),
                            filters: productCodeSearchMemo,
                            filterSearch: true,
                            onFilter: (value: any, record: any) =>
                              record["Product Code"] === value,
                          },
                          {
                            title: "Vendor",
                            dataIndex: "Vendor",
                            key: "vendor",
                            sorter: (a: any, b: any) =>
                              a["Vendor"].localeCompare(b["Vendor"]),
                            filters: vendorSearchMemo,
                            filterSearch: true,
                            onFilter: (value: any, record: any) =>
                              record["Vendor"] === value,
                          },
                          {
                            title: "Warehouse",
                            dataIndex: "Warehouse",
                            key: "warehouse",
                            filters: warehouseSearchMemo,
                            filterSearch: true,
                            sorter: (a: any, b: any) =>
                              a["Warehouse"].localeCompare(b["Warehouse"]),
                            onFilter: (value: any, record: any) =>
                              record["Warehouse"].trim().toLowerCase() ===
                              value.toLowerCase(),
                          },
                          {
                            title: "Size: 2",
                            dataIndex: "2",
                            key: "2",
                            align: "right",
                          },
                          {
                            title: "Size: 3",
                            dataIndex: "3",
                            key: "3",
                            align: "right",
                          },
                          {
                            title: "Size: 4",
                            dataIndex: "4",
                            key: "4",
                            align: "right",
                          },
                          {
                            title: "Size: 5",
                            dataIndex: "5",
                            key: "5",
                            align: "right",
                          },
                          {
                            title: "Size: 6",
                            dataIndex: "6",
                            key: "6",
                            align: "right",
                          },
                          {
                            title: "Size: 7",
                            dataIndex: "7",
                            key: "7",
                            align: "right",
                          },
                          {
                            title: "Size: 8",
                            dataIndex: "8",
                            key: "8",
                            align: "right",
                          },
                          {
                            title: "Size: 9",
                            dataIndex: "9",
                            key: "9",
                            align: "right",
                          },
                          {
                            title: "Size: 10",
                            dataIndex: "10",
                            key: "10",
                            align: "right",
                          },
                          {
                            title: "Size: 11",
                            dataIndex: "11",
                            key: "11",
                            align: "right",
                          },
                          {
                            title: "Size: 12",
                            dataIndex: "12",
                            key: "12",
                            align: "right",
                          },
                          {
                            title: "Size: 13",
                            dataIndex: "13",
                            key: "13",
                            align: "right",
                          },
                        ]}
                      />
                    </div>
                  </div>
                ),
              },
            ]}
            tabBarExtraContent={
              <Button
                type="primary"
                onClick={() => {
                  handleConvertToExcelAndDownload();
                }}
                icon={<DownloadOutlined />}
                loading={loading}
              >
                Download Report
              </Button>
            }
          />
        </div>
      )}
    </div>
  );
}
