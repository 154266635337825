import { Row, Col, Typography, Button, Space, Table, Tag } from "antd";
import { BASE_PATH, TITLE } from ".";
import { PlusOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import service from "../../services/grn";
import vendorService from "../../services/vendor";
import { debounce } from "throttle-debounce";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import { useCallback, useEffect, useState } from "react";
const { Title } = Typography;

const MASTER_KEYS = [
  {
    service: vendorService,
    value: "vendors",
  },
];

export default function Entities(props: any) {
  const navigate = useNavigate();
  const clientState = useSelector((state: Store) => state.client);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({
      onFetch: service.paginate,
      defaultFilters: {
        client: [clientState.current],
      },
    });
  const purchaseWarehouseState = useSelector(
    (state: Store) => state.purchaseWarehouse
  );
  const [masters, setMasters] = useState<any>({});

  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id, record) => (
        <Space>
          {id} {!record.isActive && <Tag>Void</Tag>}
        </Space>
      ),
    },
    {
      key: "vendor",
      title: "Vendor",
      dataIndex: "vendor",
      filters: masters.vendors?.map((v: any) => ({
        text: v.name,
        value: v._id,
      })),
      filterSearch: true,
      render: (vendor) => vendor?.name,
    },
    {
      title: "Purchase Order",
      dataIndex: "purchaseOrder",
      key: "purchaseOrder",
      render: (purchaseOrder) => purchaseOrder?.id,
    },
    {
      title: "Total Received Quantity",
      dataIndex: "totalReceivedQuantity",
      key: "totalReceivedQuantity",
    },
    {
      title: "Total Rejected Quantity",
      dataIndex: "totalRejectedQuantity",
      key: "totalRejectedQuantity",
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (startAt) => dayjs(startAt).format("DD/MM/YY hh:mm A"),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.isActive && (
            <div
              onClick={() => navigate(`${BASE_PATH}/form/${record._id}`)}
              className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
            >
              <EditOutlined /> Edit
            </div>
          )}
          <div
            onClick={() => navigate(`${BASE_PATH}/${record._id}`)}
            className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
          >
            <EyeOutlined /> View
          </div>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          client: [clientState.current],
        },
      });
    }
  }, [clientState.current]);

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/bulk`)}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;
              setTableState({
                pagination,
                filters: { ...filters, client: [clientState.current] },
                sorter,
              });
              setTableState({ pagination, filters, sorter });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
}
