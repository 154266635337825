import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/raw-material-po";
export const register = (data: any) => postRequest(SERVICE_URL, data);
export const update = (data: any) => putRequest(SERVICE_URL, data);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);
export const get = (id: string) => getRequest(`${SERVICE_URL}/${id}`);
export const getForVendorId = (id: string) =>
  getRequest(`${SERVICE_URL}/vendor/${id}`);
export const voidPO = (id: string) => putRequest(`${SERVICE_URL}/void/${id}`);
export const getForPO = (id: string) => getRequest(`${SERVICE_URL}/po/${id}`);
export const getPendingApprovalPOs = () =>
  getRequest(`${SERVICE_URL}/pending-approval`);
export default {
  paginate,
  get,
  getForVendorId,
  register,
  update,
  voidPO,
  getForPO,
  getPendingApprovalPOs,
};
