import {
  Row,
  Col,
  Typography,
  Button,
  Space,
  Table,
  Select,
  Tag,
  Modal,
  Dropdown,
  Input,
  message,
} from "antd";
import { BASE_PATH, TITLE } from ".";
import {
  PlusOutlined,
  EditOutlined,
  EyeOutlined,
  FileAddOutlined,
  AuditOutlined,
  DownOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useSearchParamsTableState from "../../hooks/useSearchParamsTableState";
import service from "../../services/rwpo";
import vendorService from "../../services/vendor";
import supplierService from "../../services/supplier";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { Store } from "../../store";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import usePermission from "../../hooks/usePermission";
import ConfirmationModal from "../../components/ConfirmationModal";

const { Title } = Typography;
const MASTER_KEYS = [
  {
    service: vendorService,
    value: "vendors",
  },
  {
    service: supplierService,
    value: "suppliers",
  },
];

export default function Entities(props: any) {
  const navigate = useNavigate();
  const [masters, setMasters] = useState<any>({});
  const permissions = usePermission({ module: "raw-material-purchase-orders" });
  const clientState = useSelector((state: Store) => state.client);
  const [openingRecord, setOpeningRecord] = useState<any>();
  const [cancellingRecord, setCancellingRecord] = useState<any>();
  const [approvingRecord, setApprovingRecord] = useState<any>();
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({
      onFetch: service.paginate,
      defaultFilters: {
        client: [clientState.current],
      },
    });
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      render: (supplier) => supplier?.name,
      filters: masters.suppliers?.map((v: any) => ({
        text: v.name,
        value: v._id,
      })),
      filterSearch: true,
    },
    {
      title: "Vendor",
      dataIndex: "vendor",
      key: "vendor",
      render: (vendor) => vendor?.name,
      filters: masters.vendors?.map((v: any) => ({
        text: v.name,
        value: v._id,
      })),
      filterSearch: true,
    },
    {
      title: "Linked POs",
      dataIndex: "po",
      key: "po",
      render: (pos) => {
        const items = pos.map((po: any) => ({
          key: po._id,
          label: po.id,
          onClick: () =>
            window.open(`/purchases/purchase-orders/${po._id}`, `_blank`),
        }));
        return (
          <Dropdown menu={{ items }}>
            <a
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <Space>
                {pos.length} POs
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => {
        const tags = [
          {
            value: "Pending Approval",
            label: <Tag color="orange">Pending Approval</Tag>,
          },
          { value: "Open", label: <Tag color="cyan">Open</Tag> },
          { value: "Closed", label: <Tag color="green">Closed</Tag> },
          { value: "Cancelled", label: <Tag>Cancelled</Tag> },
        ];
        return tags.find((tag) => tag.value === value)?.label;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (startAt) => dayjs(startAt).format("DD/MM/YY hh:mm A"),
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          {["Open", "Pending Approval"].includes(record.status) && (
            <>
              <div
                onClick={() => navigate(`${BASE_PATH}/form/${record._id}`)}
                className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
              >
                <EditOutlined /> Edit
              </div>
              {record.status === "Pending Approval" && permissions.approve && (
                <div
                  onClick={() => setApprovingRecord(record)}
                  className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
                >
                  <CheckOutlined /> Approve
                </div>
              )}
            </>
          )}
          <div
            onClick={() => navigate(`${BASE_PATH}/${record._id}`)}
            className="text-blue-500 flex gap-x-2 items-baseline cursor-pointer"
          >
            <EyeOutlined /> View
          </div>
        </Space>
      ),
    },
  ];

  console.log(permissions);

  useEffect(() => {
    if (tableState) {
      setTableState({
        ...tableState,
        filters: {
          ...tableState.filters,
          client: [clientState.current],
        },
      });
    }
  }, [clientState, tableState]);

  return (
    <div>
      <ConfirmationModal
        title="Approve Purchase Order"
        visible={!!approvingRecord}
        message="Are you sure you want to approve this purchase order?"
        onConfirm={() => {
          if (approvingRecord) {
            service
              .update({ ...approvingRecord, status: "Open" })
              .then(() => {
                message.success("PO approved successfully");
                onRefresh();
                setApprovingRecord(undefined);
              })
              .catch(() => {
                message.error("Failed to approve PO");
              });
          }
        }}
        onReject={() => {
          if (approvingRecord) {
            service
              .update({ ...approvingRecord, status: "Cancelled" })
              .then(() => {
                message.success("PO rejected successfully");
                onRefresh();
                setApprovingRecord(undefined);
              })
              .catch(() => {
                message.error("Failed to rejected PO");
              });
          }
        }}
        onCancel={() => setApprovingRecord(undefined)}
        confirmText="Approve"
        cancelText="Reject"
        confirmButtonType="primary"
      />
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Input.Search
              placeholder="Search"
              onChange={(e) => setInput(e.target.value)}
              value={input}
            />

            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/form`)}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          </Space>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;
              setTableState({
                pagination,
                filters: { ...filters, client: [clientState.current] },
                sorter,
              });
              setTableState({ pagination, filters, sorter });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
}
