import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Table,
  TimePicker,
  Tooltip,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_PATH, TITLE } from ".";
import service from "../../../services/rawMaterial";
import { Store } from "../../../store";
import { titleCase } from "title-case";

const { Title } = Typography;

export default function EntityForm(props: any) {
  const [id, setId] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [form] = Form.useForm();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [dependencies, setDependencies] = useState<any>({});
  const [existingRMs, setExistingRMs] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selectedGroup = Form.useWatch("group", form);
  console.log("selectedGroup", selectedGroup);

  useEffect(() => {
    service.getDependencies().then((data) => {
      const [groups, categories, subCategories, names] = data;
      setDependencies({
        groups,
        categories,
        subCategories,
        names,
      });
    });
    service.getAll().then((data) => {
      setExistingRMs(data);
    });
  }, []);

  const parseForPush = (permissions: any[]) => {
    const payload = {};
    for (let p of permissions) {
      const { module, values } = p;
      payload[module] = {};
      for (let value of values) {
        payload[module][value] = true;
      }
    }
    return payload;
  };
  const parseForPull = (permissions: any) => {
    const payload = [];
    Object.keys(permissions).forEach((key) => {
      const output = { module: key, values: [] };

      Object.keys(permissions[key]).forEach((k) => {
        if (permissions[key][k]) {
          output.values.push(k);
        }
      });
      payload.push(output);
    });
    return payload;
  };
  const handleSubmit = async (values: any) => {
    try {
      console.log(values);
      values.group = Array.isArray(values.group)
        ? values.group[0]
        : values.group;
      values.category = Array.isArray(values.category)
        ? values.category[0]
        : values.category;
      values.subCategory = Array.isArray(values.subCategory)
        ? values.subCategory[0]
        : values.subCategory;
      values.name = Array.isArray(values.name) ? values.name[0] : values.name;
      console.log(values);

      if (id) {
        await service.update({ ...values, _id: id });
      } else {
        await service.create(values);
      }
      navigate(BASE_PATH);
    } catch (error) {}
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    if (id) {
      setId(id);
      service.get(id).then((data) => {
        form.setFieldsValue(data);
      });
    }
  }, [location]);
  return (
    <div>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <div className="mt-4 bg-white shadow p-4">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ price: 1, supplierPrice: 1 }}
        >
          <Form.Item
            name="group"
            label="Group"
            className="w-full"
            rules={[{ required: true, message: "Group is required" }]}
          >
            <Select mode="tags" placeholder="Select group">
              {dependencies.groups?.map((g: any) => (
                <Select.Option key={g} value={g}>
                  {g}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex gap-x-2">
            <Form.Item
              name="name"
              label="Name"
              className="w-full"
              rules={[{ required: true, message: "Name is required" }]}
            >
              <Select mode="tags" placeholder="Select name">
                {existingRMs
                  ?.filter((rm) => rm.group === selectedGroup?.[0])
                  ?.map((rm) => rm.name)
                  ?.map((g: any) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="category"
              label="Category"
              className="w-full"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select mode="tags" placeholder="Select category">
                {dependencies.categories?.map((g: any) => (
                  <Select.Option key={g} value={g}>
                    {g}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex gap-x-2">
            <Form.Item
              label="Unit"
              name="unit"
              className="w-full"
              rules={[{ required: true, message: "Unit is required" }]}
            >
              <Select className="w-full" placeholder="Select unit">
                {[
                  { label: "Liter", value: "liter" },
                  { label: "Meter", value: "meter" },
                  { label: "PCS", value: "pcs" },
                  { label: "Pairs", value: "pairs" },
                  { label: "Box", value: "box" },
                ].map((g: any) => (
                  <Select.Option key={g.value} value={g.value}>
                    {g.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="price" label="Vendor Price" className="w-full">
              <InputNumber
                keyboard={false}
                className="w-full"
                placeholder="Enter vendor price"
              />
            </Form.Item>
            <Form.Item
              name="supplierPrice"
              label="Supplier Price"
              className="w-full"
            >
              <InputNumber
                keyboard={false}
                className="w-full"
                placeholder="Enter supplier price"
              />
            </Form.Item>
          </div>

          <Form.Item>
            <Space>
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
