import { message, Space, Steps, Table, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import type { UploadProps } from "antd";
import service from "../../services/sjitStock";
import {
  InboxOutlined,
  InfoCircleOutlined,
  DownloadOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Alert, Button, Divider, Upload, Tooltip } from "antd";
import * as XLSX from "xlsx";
import { titleCase } from "title-case";
const { Dragger } = Upload;

const REQUIRED_COLUMNS = ["Fulfillment Center", "SKU Code", "Quantity"];

const UploadRender: React.FC<any> = ({ onChange }) => {
  const [uploading, setUploading] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [error, setError] = useState<any>({ title: "", message: "" });
  const [data, setData] = useState<any[]>([]);
  const [reportRender, setReportRender] = useState<any>(null);
  const [checkingSkuCodes, setCheckingSkuCodes] = useState(false);
  const [unrecognizedSkuCodes, setUnrecognizedSkuCodes] = useState<any[]>();
  const [unrecognizedProductCodes, setUnrecognizedProductCodes] =
    useState<any[]>();
  const uploadProps: UploadProps = {
    name: "file",
    multiple: false,
    onChange(info) {
      if (info.fileList.length === 0) {
        setFileList([]);
      }

      const { status } = info.file;
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
  };

  const handleNext = async () => {
    try {
      setUploading(true);

      const file = fileList?.[0];
      const formData = new FormData();
      formData.append("file.xlsx", file);
      try {
        const response = await service.upload(formData);
        onChange(response);
      } catch (uploadError) {
        message.error("File upload failed."); // Notify user about the error
      }
    } catch (error) {
    } finally {
      setUploading(false);
    }
  };

  const validateColumns = (row: { [key: string]: any }): string[] => {
    return REQUIRED_COLUMNS.filter((column) => !(column in row));
  };
  const findEmptyRequiredValues = (
    data: Array<{ [key: string]: any }>
  ): any[] => {
    let emptyDetails: any[] = [];

    data.forEach((row, index) => {
      REQUIRED_COLUMNS.forEach((column) => {
        if (!(column in row) || row[column] === null || row[column] === "") {
          emptyDetails.push({ row: index + 2, column: column });
        }
      });
    });

    return emptyDetails;
  };

  const readFile = useCallback((file: File) => {
    try {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        const workbook = XLSX.read(e.target.result, {
          type: "binary",
          raw: true,
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, {
          defval: null,
        });
        setData(jsonData);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      message.error("Error reading the file.");
    }
  }, []);

  useEffect(() => {
    if (fileList.length === 0) {
      return () => {};
    }
    readFile(fileList[0]);
  }, [fileList]);

  useEffect(() => {
    if (fileList.length === 0) {
      setReportRender(null);
      return () => {};
    }
    console.log(data);

    if (data.length === 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message="No data was found in the file, please validate that mappings are present in the first sheet."
          showIcon
        />
      );
      return () => {};
    }

    setReportRender(null);

    const missingColumns = validateColumns(data[0]);

    if (missingColumns.length > 0) {
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          message={`Missing required columns:`}
          description={`${missingColumns.join(", ")}`}
          showIcon
        />
      );
      return () => {};
    }

    const emptyDetails: { row: number; column: string }[] =
      findEmptyRequiredValues(data);

    if (emptyDetails?.length > 0) {
      const missingRequiredValuesMap: { [key: string]: number[] } = {};
      emptyDetails.forEach((detail) => {
        if (!missingRequiredValuesMap[detail.column]) {
          missingRequiredValuesMap[detail.column] = [];
        }
        missingRequiredValuesMap[detail.column].push(detail.row);
      });
      setReportRender(
        <Alert
          className="mt-4"
          type="error"
          description={
            <div>
              {Object.entries(missingRequiredValuesMap).map(
                ([column, rows]) => (
                  <div key={column}>
                    <strong>{titleCase(column)}:</strong>{" "}
                    <Tooltip
                      placement="right"
                      title={
                        <div>
                          <div>Rows: {rows.join(", ")}</div>
                        </div>
                      }
                    >
                      <span className="text-blue-500">
                        Rows <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  </div>
                )
              )}
            </div>
          }
          message={
            <div>
              <div>Some required values are missing in the file.</div>
            </div>
          }
          showIcon
        />
      );
      return () => {};
    }
  }, [data, fileList]);

  return (
    <div>
      <Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
      </Dragger>
      {error.title && (
        <Alert
          message={error.title}
          description={error.message}
          type="error"
          showIcon
        />
      )}
      <Divider className="my-2" />
      <div className="flex justify-between ">
        <Button disabled>Previous</Button>
        <Button
          type="primary"
          onClick={handleNext}
          disabled={fileList.length === 0 || uploading}
          loading={uploading}
        >
          Next
        </Button>
      </div>
      <div>{reportRender}</div>
    </div>
  );
};

const ApproveProcess: React.FC<any> = ({
  data,
  onChange,
  onPrevious,
  loading,
}) => {
  const {
    data: payload,
    invalidSkuCodes,
    invalidWarehouses,
    unlinkedSkuCodes,
  } = data;

  const handleNext = useCallback(() => {
    onChange();
  }, [data, onChange]);
  const columns: any[] = [
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (text: string) => {
        if (text === "OK") {
          return <Tag color="green">OK</Tag>;
        } else if (text === "Sku not found") {
          return <Tag color="red">SKU Not Found</Tag>;
        } else if (text === "Mapping not found") {
          return <Tag color="red">Mapping Not Found</Tag>;
        } else if (text === "Warehouse not found") {
          return <Tag color="red">Warehouse Not Found</Tag>;
        }
      },
      filters: [
        {
          text: `OK (${payload.filter((r) => r.status === "OK").length})`,
          value: "OK",
        },
        {
          text: `Sku not found (${
            payload.filter((r) => r.status === "Sku not found").length
          })`,
          value: "Sku not found",
        },
        {
          text: `Mapping not found (${
            payload.filter((r) => r.status === "Mapping not found").length
          })`,
          value: "Mapping not found",
        },
        {
          text: `Warehouse not found (${
            payload.filter((r) => r.status === "Warehouse not found").length
          })`,
          value: "Warehouse not found",
        },
      ],
      onFilter: (value: string, record: any) =>
        record.status.indexOf(value) === 0,
    },
    {
      key: "fulfillmentCenter",
      title: "Fulfillment Center",
      dataIndex: "fulfillmentCenterName",
    },
    {
      key: "sku",
      title: "SKU",
      dataIndex: "skuCode",
      render: (skuCode: string, record: any) => (
        <span>
          {skuCode} <ArrowRightOutlined /> {record.omsSkuCode}{" "}
        </span>
      ),
    },
    {
      key: "quantity",
      title: "Quantity",
      dataIndex: "quantity",
    },
  ];

  const handleDownloadErroredRows = async () => {
    try {
      const ws = XLSX.utils.json_to_sheet(
        payload
          .filter((r) => r.status !== "OK")
          .map((r) => ({
            Warehouse: r.warehouseName,
            "SKU Code": r.skuCode,
            Quantity: r.quantity,
          }))
      );
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Errored Rows");
      XLSX.writeFile(wb, "errored_rows.xlsx");
    } catch (error) {
      throw error;
    }
  };

  return (
    <div className="">
      <Table
        className=""
        pagination={{ defaultPageSize: 20 }}
        dataSource={payload || []}
        size="small"
        columns={columns}
      />
      <Divider className="my-2" />
      <div>
        <Alert
          type="warning"
          message={
            <div>
              Download errored rows:{" "}
              <span
                className="text-blue-500 cursor-pointer"
                onClick={() => handleDownloadErroredRows()}
              >
                <DownloadOutlined className="mr-1" />
                Download
              </span>
            </div>
          }
        />
      </div>
      <Divider className="my-2" />
      <div className="flex justify-between">
        <Button onClick={onPrevious}>Previous</Button>
        <Button type="primary" onClick={handleNext} loading={loading}>
          Approve
        </Button>
      </div>
    </div>
  );
};

const Report = (props: any) => {
  const { data } = props;
  console.log(data);

  const columns: any[] = [
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (text: string) => {
        if (text === "Success") {
          return <Tag color="green">Success</Tag>;
        } else if (text === "Sku not found") {
          return <Tag color="red">SKU Not Found</Tag>;
        } else if (text === "Mapping not found") {
          return <Tag color="red">Mapping Not Found</Tag>;
        } else if (text === "Warehouse not found") {
          return <Tag color="red">Warehouse Not Found</Tag>;
        }
      },
    },
    {
      key: "fulfillmentCenter",
      title: "Fulfillment Center",
      dataIndex: "fulfillmentCenterName",
    },
    {
      key: "sku",
      title: "SKU",
      dataIndex: "skuCode",
      render: (skuCode: string, record: any) => (
        <span>
          {skuCode} <ArrowRightOutlined /> {record.omsSkuCode}{" "}
        </span>
      ),
    },
    {
      key: "quantity",
      title: "Quantity",
      dataIndex: "quantity",
    },
  ];
  return (
    <div>
      {data && (
        <Table
          dataSource={data}
          pagination={{ defaultPageSize: 20 }}
          size="small"
          columns={columns}
        />
      )}
    </div>
  );
};

function SJITForm(props: any) {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState<any>();
  const [uploadResult, setUploadResult] = useState<any>();
  const handleApprove = async () => {
    try {
      setLoading(true);
      const report = await service.process({ ...uploadResult });
      console.log(report);

      setReport(report);
      setStep(2);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };
  const render = () => {
    switch (step) {
      case 0: {
        return (
          <UploadRender
            onChange={(data) => {
              setStep(1);
              setUploadResult(data);
            }}
          />
        );
      }
      case 1: {
        return (
          <ApproveProcess
            data={uploadResult}
            onChange={handleApprove}
            onPrevious={() => setStep(0)}
            loading={loading}
          />
        );
      }
      case 2: {
        return <Report data={report} onPrevious={() => setStep(0)} />;
      }
    }
  };
  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        New SJIT Inventory Upload
      </div>
      <div className="mt-8">
        <Steps
          progressDot
          size="small"
          current={step}
          onChange={() => {}}
          items={[
            {
              title: "Upload File",
            },
            {
              title: "Approve & Process",
            },
            {
              title: "Review Report",
            },
          ]}
        />
      </div>
      <div className="bg-white rounded-lg mt-2 shadow px-2 py-2">
        {render()}
      </div>
    </div>
  );
}

export default SJITForm;
