import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import service from "../../services/po";
import {
  Button,
  Col,
  Descriptions,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
} from "antd";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import PurchaseOrderPDFModal from "../../components/PurchaseOrderPDFModal";
import getDocDefinition from "../../utils/getPODocDefinition";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import JSZip from "jszip";
import skuService from "../../services/sku";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  FileZipOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import usePrintQueue from "../../hooks/usePrintQueue";
import settings from "../../services/settings";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function Info(props: any) {
  return (
    <Descriptions className="mt-2">
      <Descriptions.Item label="Vendor">
        {props.data.vendor?.name}
      </Descriptions.Item>
      <Descriptions.Item label="Total SKUs">
        {props.data.totalSKUs}
      </Descriptions.Item>
      <Descriptions.Item label="Total Quantity">
        {props.data.totalQuantity}
      </Descriptions.Item>
      <Descriptions.Item label="Started At">
        {dayjs(props.data.startAt).format("DD/MM/YY hh:mm A")}
      </Descriptions.Item>
      <Descriptions.Item label="Expires At">
        {dayjs(props.data.expiryAt).format("DD/MM/YY hh:mm A")}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        {props.data.status === "Open" ? (
          <Tag color="cyan">Open</Tag>
        ) : props.data.status === "Closed" ? (
          <Tag color="green">Closed</Tag>
        ) : (
          <Tag>Cancelled</Tag>
        )}
      </Descriptions.Item>
    </Descriptions>
  );
}

export function SKUTable(props: any) {
  const [grns, setGRNs] = useState<any[]>();

  const [grnMap, setGRNMap] = useState<{
    [key: string]: { received: number; rejected: number };
  }>({});
  useEffect(() => {
    handleLoadGRNs(props.data._id);
  }, [props.data]);
  const handleLoadGRNs = (id: string) => {
    service.getGRNsForPurchaseOrderId(id).then((grns) => {
      setGRNs(grns);
    });
  };
  useEffect(() => {
    if (!grns) {
      return;
    }
    const newMap: any = {};
    for (let grn of grns) {
      for (let sku of grn.skus.map((sku) => sku.quantities).flat()) {
        if (!newMap[sku.skuCode]) {
          newMap[sku.skuCode] = { received: 0, rejected: 0 };
        }
        newMap[sku.skuCode].received += sku.received;
        newMap[sku.skuCode].rejected += sku.rejected;
      }
    }
    setGRNMap(newMap);
  }, [grns]);
  return (
    <>
      <Title level={4} className="p-2">
        Products
      </Title>
      <Table
        size="small"
        dataSource={props.data.skus.map((sku) => sku.quantities).flat()}
        columns={[
          {
            title: "Product Code",
            dataIndex: "skuCode",
            key: "skuCode",
          },
          {
            title: "Ordered",
            dataIndex: "ordered",
            key: "ordered",
          },
          {
            title: "Received",
            dataIndex: "received",
            key: "received",
            render: (text: any, record: any) => {
              return grnMap[record.skuCode]?.received || 0;
            },
          },
          {
            title: "Rejected",
            dataIndex: "rejected",
            key: "rejected",
            render: (text: any, record: any) => {
              return grnMap[record.skuCode]?.rejected || 0;
            },
          },
          {
            title: "Pending",
            dataIndex: "pending",
            key: "pending",
            render: (text: any, record: any) => {
              return record.ordered - (grnMap[record.skuCode]?.received || 0);
            },
          },
          {
            title: "Print",
            key: "print",
            dataIndex: "skuCode",
            render: (skuCode, record) => {
              return (
                <Button
                  type="link"
                  size="small"
                  onClick={() =>
                    props.onAddToPrintQueue(skuCode, record.ordered)
                  }
                  disabled={!props.isAvailable}
                >
                  Print
                </Button>
              );
            },
          },
        ]}
      />
    </>
  );
}

export default function View(props: any) {
  const params = useParams();
  const navigate = useNavigate();
  const [printQueue, isAvailable] = usePrintQueue();
  const [printLoading, setPrintLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [showVoidModal, setShowVoidModal] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  useEffect(() => {
    if (params.id) {
      handleLoad(params.id);
    }
  }, [params]);

  const handleLoad = (id: string) => {
    service.get(id).then((p) => {
      for (let i = 0; i < p.skus.length; i++) {
        const sku = p.skus[i];
        const quantities = sku.quantities.filter((q) => q.ordered > 0);
        p.skus[i].quantities = quantities;
      }
      setData(p);
    });
  };
  const generateExcelFile = () => {
    if (!data) return null;

    const wsData = [
      ["STYLE CODE", "Size", "SKU ID", "Colour code", "Qty", "Vendor Code"],
    ];
    for (let sku of data.skus) {
      const { productCode, quantities } = sku;

      for (let quantity of quantities) {
        wsData.push([
          productCode,
          quantity.size,
          quantity.skuCode,
          "",
          quantity.ordered,
          data.vendor.code,
        ]);
      }
    }

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    return new Blob([wbout], { type: "application/octet-stream" });
  };
  const handleAddToPrintQueue = async (skuCode, ordered) => {
    const sku = data.skus.find((sku) =>
      sku.quantities.find((q) => q.skuCode === skuCode)
    );

    const quantity = sku.quantities.find((q) => q.skuCode === skuCode);
    const skuObjects = await skuService.getbySkuCodes([quantity.skuCode]);
    console.log(skuObjects);
    const payload: any[] = [];
    for (let i = 0; i < ordered; i++) {
      payload.push({
        ...quantity,
        size: +quantity.size,
        euSize:
          +quantity.size +
          (skuObjects?.[0]?.skuGenderCode === "male" ? 34 : 33),
        quantity: 1,
        barcodeValue: quantity.skuCode,
        color: skuObjects?.[0]?.skuColorCode || "",
        vendor: data.vendor.code,
        skuCode:
          skuObjects?.[0]?.encodedSkuCode || skuObjects?.[0]?.skuCode || "",
      });
    }
    console.log(payload);

    printQueue.push(payload);
  };

  const getMappingForSkuCodes = async (skuCodes: string[]) => {
    const mappings = await settings.getMappingForSkuCodes(skuCodes);
    return mappings;
  };

  const handlePrintAll = async () => {
    setPrintLoading(true);
    const payload = [];

    const skuObjects = await skuService.getbySkuCodes(
      data.skus.map((sku) => sku.quantities.map((q) => q.skuCode)).flat()
    );

    console.log(data.skus);

    for (let sku of data.skus) {
      const quantities = sku.quantities;
      const quantity = quantities[0];
      const skuObject = skuObjects.find((s) => s.skuCode === quantity.skuCode);

      for (let quantity of quantities) {
        for (let i = 0; i < quantity.ordered; i++) {
          payload.push({
            ...quantity,
            size: +quantity.size,
            euSize:
              +quantity.size + (skuObject?.skuGenderCode === "male" ? 34 : 33),
            quantity: 1,
            barcodeValue: quantity.skuCode,
            color: skuObject?.skuColorCode || "",
            vendor: data.vendor.code,
            skuCode: skuObject?.encodedSkuCode || skuObject?.skuCode || "",
          });
        }
      }
    }

    const batchSize = 100;
    const numBatches = Math.ceil(payload.length / batchSize);

    try {
      // Process each batch sequentially
      for (let batchIndex = 0; batchIndex < numBatches; batchIndex++) {
        const batch = payload.slice(
          batchIndex * batchSize,
          (batchIndex + 1) * batchSize
        );
        await printQueue.push(batch); // Push each batch to the print queue
      }
    } catch (error) {
      console.error("Error pushing to print queue:", error);
    } finally {
      setPrintLoading(false);
    }
  };
  const downloadZip = async () => {
    if (!data) return;
    const filename = data.id.split("/").join("_");

    const { skus } = data;

    const skuCodes = skus
      .map((sku: any) => sku.quantities)
      .flat()
      .map((quantity: any) => quantity.skuCode);

    const mappings = await Promise.all(
      skuCodes.map((skuCode: string) => skuService.getMappingForSku(skuCode))
    );

    for (let i = 0; i < skus.length; i++) {
      const sku = skus[i];
      sku.mapping = mappings[i];
    }

    const docDefinition: any = getDocDefinition(data);

    pdfMake.createPdf(docDefinition).getBlob(async (pdfBlob) => {
      const zip = new JSZip();
      zip.file(`${filename}.pdf`, pdfBlob);

      const excelBlob = generateExcelFile();
      if (excelBlob) {
        zip.file(`${filename}.xlsx`, excelBlob);
      }

      const content = await zip.generateAsync({ type: "blob" });
      saveAs(content, `${filename}.zip`);
    });
  };

  const voidPO = () => {
    if (!data) return;
    service.voidPO(data._id).then(() => {
      message.success("PO voided successfully");
      handleLoad(data._id);
    });
  };

  const closePO = () => {
    if (!data) return;
    service.update({ ...data, status: "Closed" }).then(() => {
      message.success("PO closed successfully");
      handleLoad(data._id);
    });
  };

  return (
    data && (
      <div>
        <Modal
          title="Void PO"
          visible={showVoidModal}
          onOk={() => {
            setShowVoidModal(false);
            voidPO();
          }}
          onCancel={() => setShowVoidModal(false)}
        >
          <p>Are you sure you want to void this PO?</p>
        </Modal>
        <Modal
          title="Close PO"
          visible={showCloseModal}
          onOk={() => {
            setShowCloseModal(false);
            closePO();
          }}
          onCancel={() => setShowCloseModal(false)}
        >
          <p>Are you sure you want to close this PO?</p>
        </Modal>
        <Row justify="space-between">
          <Col>
            <Title level={3}>{data.id}</Title>
          </Col>
          <Col></Col>
          <Col>
            <PurchaseOrderPDFModal id={data._id} />
            <Button
              type="primary"
              onClick={downloadZip}
              style={{ marginLeft: 8 }}
              icon={<FileZipOutlined />}
            >
              Download Zip
            </Button>
          </Col>
        </Row>
        <div className="bg-white rounded-lg p-2">
          <Info data={data} />
        </div>
        {["Open", "In Progress"].includes(data.status) && (
          <div className="flex gap-x-2 justify-end mt-2">
            <Button
              type="link"
              icon={<PlusCircleOutlined />}
              onClick={() =>
                navigate(
                  `/goods-receive-notes/bulk?po=${data._id}&vendor=${data.vendor._id}`
                )
              }
            >
              Create GRN
            </Button>
            <Button
              type="link"
              icon={<CloseCircleOutlined />}
              onClick={() => setShowVoidModal(true)}
            >
              Void PO
            </Button>
            <Button
              type="link"
              icon={<CheckCircleOutlined />}
              onClick={() => setShowCloseModal(true)}
            >
              Close PO
            </Button>
            <Button
              type="link"
              onClick={handlePrintAll}
              icon={<PrinterOutlined />}
              loading={printLoading}
            >
              Print All Labels
            </Button>
          </div>
        )}
        <div className="mt-2 bg-white rounded-lg">
          <SKUTable
            data={data}
            onAddToPrintQueue={handleAddToPrintQueue}
            isAvailable={isAvailable}
          />
        </div>
      </div>
    )
  );
}
