import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Tag,
  Switch,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  SwapOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "../../../services/rawMaterial";

import useSearchParamsTableState from "../../../hooks/useSearchParamsTableState";
import { useDispatch, useSelector } from "react-redux";

import { saveAs } from "file-saver";
import { Store } from "../../../store";
import { titleCase } from "title-case";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [];

export default function Entities(props: any) {
  const dispatch = useDispatch();
  const [exportLoading, setExportLoading] = useState(false);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const navigate = useNavigate();
  const [dependencies, setDependencies] = useState<any>({});

  const clientState = useSelector((state: Store) => state.client);
  useEffect(() => {
    loadMastersDebounced();
  }, []);
  useEffect(() => {
    service.getDependencies().then((data) => {
      const [groups, categories, subCategories] = data;
      setDependencies({
        groups,
        categories,
        subCategories,
      });
    });
  }, []);

  const [masters, setMasters] = useState<any>({});

  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns: any[] = [
    {
      title: "Group",
      dataIndex: "group",
      key: "group",
      filters: dependencies.groups?.map((group) => ({
        text: group,
        value: group,
      })),
      filterSearch: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: dependencies.categories?.map((group) => ({
        text: group,
        value: group,
      })),
      filterSearch: true,
    },

    {
      title: "Vendor Price",
      dataIndex: "price",
      key: "price",
      render: (price, record) => `${price} / ${record.unit}`,
    },
    {
      title: "Supplier Price",
      dataIndex: "supplierPrice",
      key: "supplierPrice",
      render: (price, record) => `${price} / ${record.unit}`,
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",

      render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (text, record, index) => (
        <Button
          type="link"
          onClick={() => {
            navigate(`${BASE_PATH}/form?id=${record._id}`);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };

  return (
    <div>
      <div className="text-lg font-semibold flex justify-between items-center w-full">
        {TITLE[1]}
        <div className="flex gap-x-2">
          <Input.Search
            placeholder="Search"
            onChange={(e) => setInput(e.target.value)}
            value={input}
          />
          <div className="flex gap-x-2">
            <Button
              type="primary"
              onClick={() => navigate(`${BASE_PATH}/form`)}
              icon={<PlusOutlined />}
            >
              Add New
            </Button>
          </div>
        </div>
      </div>

      {/* <Row justify="space-between" className="p-4">
        <Col className="flex">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
        </Col>
        <Col>
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
        </Col>
        <Col></Col>
      </Row> */}
      <Row className="mt-2">
        <Col span={24}>
          <Table
            loading={loading}
            dataSource={data?.list}
            columns={columns}
            pagination={{
              ...(tableState?.pagination || {}),
              total: data?.queryCount,
            }}
            bordered
            className="overflow-table"
            onChange={(pagination, filters, sorter) => {
              delete (sorter as any).column;
              setTableState({
                pagination,
                filters: { ...filters, client: [clientState.current] },
                sorter,
              });
              setTableState({ pagination, filters, sorter });
            }}
            rowKey={(record) => record._id}
            size="small"
          />
        </Col>
      </Row>
    </div>
  );
}
