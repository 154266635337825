import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const TYPE = "PROGRESS";

export interface ProgressState {
  sjitOrders: {
    upload: number;
    process: number;
  };
}

const initialState: ProgressState = {
  sjitOrders: {
    upload: 0,
    process: 0,
  },
};

const slice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setSjitOrderUpload: (state, action) => {
      state.sjitOrders.upload = action.payload;
    },
    setSjitOrderProcess: (state, action) => {
      state.sjitOrders.process = action.payload;
    },
  },
});

export const { setSjitOrderUpload, setSjitOrderProcess } = slice.actions;

export default slice.reducer;
